import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import Header from "../components/header"
import PageTitle from "../components/page-title"
import ProductList from "../components/product-list"
import CategoryList from "../components/category-list"
import CategoryDropdown from "../components/category-dropdown"
import {FreeShippingWhite} from "../components/free-shipping"
import Icon from "../components/icon"
import _ from "lodash";
import _sortBy from "lodash/sortBy"
import _uniqBy from "lodash/uniqBy"

class Shop extends React.Component {
	prepData(categories, products) {
		const sections = categories.map(category => ({ label: category.name, items: _uniqBy(products.filter(item => item.category === category.name), 'name') }));
		return sections.filter(section => section.items.length)
	}
	render() {
		// const { edges: categories } = data.categories;
		const categories = _sortBy(this.props.data.categories.edges.map(({node}) => node), 'name');
		const sections = this.prepData(categories, this.props.data.products.edges.map(item => item.node));
		return (
			<Layout>
				<div className='ph3 ph4-ns pt3 pt0-ns'>
					<PageTitle title="Shop" />
					<div className='cf'>
						<div className='w-100 dn-l dn-xl pb4 fl'>
							<CategoryDropdown current="All Products" items={ categories } />
						</div>
						<div className='dn db-m db-l db-xl w-30-m w-25-l w-20-xl pv2 pv0-ns fl'>
							<CategoryList items={ categories } />
						</div>
						<div className='w-100 w-70-m w-75-l w-80-xl fl pb4'>
							<div className='tl tr-ns'>
								<div className='dib mb4'>
									<FreeShippingWhite />
									<p className='f7 pa0 ma0 mt2 black-50'>*$10 for orders with backdrop signs.</p>
								</div>
							</div>
							{ sections.map(section =>
									<div key={ section.label } className='mb4'>
										<h2 className='ma0 pa0 ttu tracked f6 fw4 black-50 pb1 mb3'>{ section.label }</h2>
										<ProductList items={ section.items }  />
									</div>
								)
							}
						</div>
					</div>
				</div>
			</Layout>
		)
	}
}

export default Shop;

export const query = graphql`
	query ShopQuery {
		products: allAirtableProducts( filter: { active: { eq: true } }) {
			totalCount
			edges {
				node {
					name
					active
					category
					featured
					lowest_price
					base_price
					premium_price
					material
					colors
					fields {
						slug
						path
						category_slug
						vanity_name
					}
				}
	  		}
		}
		categories: allAirtableCategories {
			totalCount
			edges {
				node {
					name
					slug
				}
	  		}
		}
  }
`